import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import dayjs from 'dayjs'
import rehypeReact from 'rehype-react'

import ReLink from '../../components/rehype/Link'
import ReTable from '../../components/rehype/Table'

import Page from '../../components/Page'
import Schema from '../../components/Schema'
import HeaderInfo from './../../components/articles/HeaderInfo'
import ShareButtons from '../../components/articles/ShareButtons'
import Hr from './../../components/articles/Hr'
import EditorialArticleLink from '../../components/articles/Listings/EditorialArticleLink'
import Newsletter from '../../components/Newsletter'

import DownloadSVG from '../../assets/svg/download.svg'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { a: ReLink, table: ReTable },
}).Compiler

export default function RecipeBookTemplate({ data }) {
  const { site, recipe, relatedArticles } = data
  const shareUrl = encodeURI(
    `${site.siteMetadata.siteUrl}${recipe.categoryLink}/${recipe.slug}`
  )
  const recipeDownloadLink = `${recipe.download.url}?dl=${recipe.download.filename}`

  const breadcrumbs = [
    {
      name: 'Editorial',
      uri: '/editorial',
    },
    {
      name: recipe.category,
      uri: recipe.categoryLink,
    },
    {
      name: recipe.title,
      uri: `${recipe.categoryLink}/${recipe.slug}`,
    },
  ]

  let recipeJSONLD = {
    '@context': 'https://schema.org/',
    '@type': 'Recipe',
    name: recipe.title,
    image: [recipe.heroImage.url],
    description: recipe.description,
    keywords: recipe.keywords,
    author: {
      '@type': 'Person',
      name: 'Erudus',
    },
    datePublished: dayjs(recipe.displayDate).format('YYYY-MM-DD'),
    prepTime: `PT${recipe.prepTime}M`,
    cookTime: `PT${recipe.bakeTime}M`,
    totalTime: `PT${recipe.prepTime + recipe.bakeTime}M`,
    recipeCuisine: recipe.cuisine,
    recipeYield: recipe.yield,
    nutrition: {
      '@type': 'NutritionInformation',
      calories: `${recipe.calories} cal`,
    },
    recipeIngredient: recipe.ingredients.map((ingredient) => {
      return ingredient.ingredient
    }),
    recipeInstructions: recipe.method.map((method, i) => {
      return {
        '@type': 'HowToStep',
        name: `Step ${i + 1}`,
        text: method.step,
        url: `${site.siteMetadata.siteUrl}${recipe.categoryLink}/${
          recipe.slug
        }#step-${i + 1}`,
      }
    }),
  }

  if (recipe.ingredientImage !== null) {
    recipeJSONLD.image.push(recipe.ingredientImage.url)
  }

  recipe.methodImages.forEach((image) => {
    recipeJSONLD.image.push(image.url)
  })

  if (recipe.categorySchema !== 'Not specified') {
    recipeJSONLD.recipeCategory = recipe.categorySchema
  }

  const showLeadIn =
    recipe.leadIn || recipe.bodyNode.childMarkdownRemark.htmlAst

  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Page className="p-article no-gradient has-fingerPrints" datoIndex>
      <HelmetDatoCms seo={recipe.seoMetaTags} />
      <Schema breadcrumbs={breadcrumbs} recipe={recipeJSONLD} />
      {recipe.canonicalUrl && (
        <Helmet
          link={[
            {
              rel: 'canonical',
              key: recipe.canonicalUrl,
              href: recipe.canonicalUrl,
            },
          ]}
        />
      )}

      <article className="c-article c-recipe">
        <div className="container py-5">
          <header>
            <HeaderInfo
              date={recipe.pubDateTime}
              category={recipe.category}
              categoryLink={recipe.categoryLink}
            />
            <div className="row">
              <div className="col-12 col-lg-9 offset-lg-3">
                <h1 className="c-articleTitle">{recipe.title}</h1>
              </div>
            </div>
          </header>

          {/* <!-- Hero --> */}
          <section>
            <div className="row d-flex align-items-end">
              <div className="col-12 col-lg-3 mb-4">
                <ShareButtons shareUrl={shareUrl} />
              </div>
              <div className="col-12 col-lg-9">
                <Img
                  fluid={recipe.heroImage.fluid}
                  title={recipe.heroImage.title}
                  alt={recipe.heroImage.alt}
                  loading="eager"
                  className="c-articleHeroImage img-fluid"
                />
              </div>
            </div>
          </section>

          {/* <!-- Intro --> */}
          <section>
            <div className="row">
              <div className="col-12 col-lg-4 mb-">
                <div>
                  <h3>Prep time</h3>
                  <p>{recipe.prepTimeLabel}</p>
                </div>
                <div>
                  <h3>Bake time</h3>
                  <p>{recipe.bakeTimeLabel}</p>
                </div>
                <div>
                  <h3>Serves</h3>
                  <p>{recipe.serves}</p>
                </div>

                <h3 style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                  Reference Intake
                </h3>

                <div className="text-center flex">
                  <img
                    src={recipe.referenceIntake.fixed.src}
                    srcSet={`${recipe.referenceIntake.fixed.src}&dpr=1 1x, ${recipe.referenceIntake.fixed.src}&dpr=2 2x, ${recipe.referenceIntake.fixed.src}&dpr=3 3x`}
                    title={recipe.referenceIntake.title}
                    alt={recipe.referenceIntake.alt}
                    className="img-fluid"
                  />
                </div>

                <br />

                <h3 style={{ paddingBottom: '20px' }}>Dietary info</h3>
                {recipe.dietaryInfo.length > 0 ? (
                  <div className="c-nutritionCardIcons">
                    {recipe.dietaryInfo.map((info) => (
                      <div key={info.title} className="c-nutritionCardIcon">
                        <Img
                          fluid={info.icon.fluid}
                          title={info.icon.title}
                          alt={info.icon.alt}
                          className="img-fluid mb-2"
                          style={{
                            width: '70%',
                            maxWidth: '100px',
                            margin: 'auto',
                          }}
                        />
                        <span>{info.title}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>N/A</p>
                )}

                <br />

                <h3 style={{ paddingBottom: '20px' }}>Allergens</h3>
                {recipe.allergens.length > 0 ? (
                  <div className="c-nutritionCardIcons">
                    {recipe.allergens.map((allergen) => (
                      <div key={allergen.title} className="c-nutritionCardIcon">
                        <Img
                          fluid={allergen.icon.fluid}
                          title={allergen.icon.title}
                          alt={allergen.icon.alt}
                          className="img-fluid mb-2"
                          style={{
                            width: '70%',
                            maxWidth: '100px',
                            margin: 'auto',
                          }}
                        />
                        <span>{allergen.title}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>N/A</p>
                )}
              </div>

              <div
                className={`col-12 col-lg-8${
                  isSmallScreen ? '' : ' c-recipeBackground '
                }`}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="col-12 mb-4 c-recipeLeadIn">
                      {showLeadIn && (
                        <>
                          {recipe.leadIn && (
                            <h2 className="c-articleLeadIn mb-4">
                              {recipe.leadIn}
                            </h2>
                          )}
                          {renderAst(
                            recipe.bodyNode.childMarkdownRemark.htmlAst
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-12 mb-2 c-recipeLeadIn">
                      <div className="row c-recipeIngredients">
                        <div className="col-12 col-md-7 mb-3">
                          <h2 className="mb-3">Ingredients</h2>
                          <ul>
                            {recipe.ingredients.map(({ ingredient }) => (
                              <li key={ingredient}>{ingredient}</li>
                            ))}
                          </ul>
                        </div>
                        {recipe.ingredientImage && (
                          <div className="col-12 col-md-5 text-center mb-3">
                            <Img
                              fluid={recipe.ingredientImage.fluid}
                              title={recipe.ingredientImage.title}
                              alt={recipe.ingredientImage.alt}
                              className="img-fluid mb-3"
                            />
                          </div>
                        )}
                      </div>

                      <div className="row c-recipeMethod">
                        <div className="col-12 mb-3">
                          <h2 className="mb-3">Method</h2>
                          <div className="col-12 mb-3">
                            {recipe.method.map(({ step }, i) => (
                              <div
                                key={`method_step_${i + 1}`}
                                id={`step-${i + 1}`}
                                className="c-recipeMethod__step col-12"
                              >
                                <p className="c-recipeMethod__stepNo">
                                  {i + 1}.
                                </p>{' '}
                                <p className="c-recipeMethod__stepText">
                                  {step}
                                </p>
                              </div>
                            ))}
                          </div>
                          <div className="col-12 col-lg-4 text-center">
                            <div className="row">
                              {recipe.methodImages.map((image, i) => (
                                <div
                                  key={`method_image_${i + 1}`}
                                  className="col-4 col-lg-12"
                                >
                                  <Img
                                    fluid={image.fluid}
                                    title={image.title}
                                    alt={image.alt}
                                    className="img-fluid mb-3"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="c-recipeFooter">
                      <a
                        download
                        href={recipeDownloadLink}
                        target="_blank"
                        className="c-button c-button--secondary"
                      >
                        <DownloadSVG className="mr-2" />
                        Download this recipe
                      </a>
                      <p>
                        Use and edit this recipe in Erudus Recipe Builder
                        <br />
                        <Link to="/caterers#pricing" className="mt-3">
                          See our subscription options{' '}
                          <img
                            className="ml-2"
                            src="/icons/arrow-right-green.svg"
                            alt=""
                          />
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>

      {/* <!-- Related --> */}
      <section
        id="latest-editorial"
        className="c-latestEditorial"
        data-datocms-noindex
      >
        <div className="container">
          <div className="c-latestEditorial__inner">
            <div className="row">
              <div className="c-latestEditorial__header col-12">
                <h3 className="c-latestEditorial__header__title">
                  Related Editorial
                </h3>
                <Link to="/editorial" className="c-postReturnLink">
                  <img src="/icons/arrow-right.svg" className="c-icon mr-2" />
                  <span className="c-postReturnLink__text">All Editorial</span>
                </Link>
              </div>
            </div>

            <div className="row">
              {relatedArticles.edges.map(({ node }) => (
                <div key={node.slug} className="col-6 col-lg-4">
                  <EditorialArticleLink article={node} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export const pageQuery = graphql`
  query RecipeBookQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    recipe: datoCmsRecipe(slug: { eq: $slug }) {
      title
      slug
      canonicalUrl
      category
      categoryLink
      pubDateTime
      leadIn
      bodyNode {
        childMarkdownRemark {
          htmlAst
        }
      }
      description
      keywords
      prepTimeLabel
      prepTime
      bakeTimeLabel
      bakeTime
      cuisine
      yield
      calories
      serves
      categorySchema
      ingredients {
        ingredient
      }
      ingredientImage {
        title
        alt
        url
        fluid(maxWidth: 550, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      referenceIntake {
        title
        alt
        fixed(width: 600) {
          src
        }
      }
      dietaryInfo {
        title
        icon {
          title
          alt
          fluid(maxWidth: 100, imgixParams: { w: "100", h: "100" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      allergens {
        title
        icon {
          title
          alt
          fluid(maxWidth: 100, imgixParams: { w: "100", h: "100" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      method {
        step
      }
      methodImages {
        title
        alt
        url
        fluid(maxWidth: 550, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      download {
        filename
        url
      }
      heroImage {
        url
        fluid(imgixParams: { w: "1110", fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    relatedArticles: allDatoCmsRecipe(
      filter: { slug: { ne: $slug } }
      sort: { fields: displayDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          relPath
          category
          categoryLink
          pubDateTime
          heroImage {
            url
            alt
            title
            customData
          }
        }
      }
    }
  }
`
