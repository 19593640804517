import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import dayjs from 'dayjs'

import ArrowLeftSVG from './../../assets/svg/arrow-left.svg'

const HeaderInfo = ({ date, category, categoryLink, fullWidth, hide }) => {
  const context = categoryLink.substr(1).split('/')[0]
  const humanDate = dayjs(date).format('DD MMM YYYY')

  const Category = () => (
    <div className="col-7 text-right col-lg-3 text-lg-left">
      <span className="c-articleDate">
        <time dateTime={date}>{humanDate}</time>
        {` • `}
        <Link to={categoryLink} className="c-articleDate__categoryLink">
          {category}
        </Link>
      </span>
    </div>
  )

  return (
    <div className="row mb-4">
      <div className={fullWidth ? 'col-5 col-lg-2' : 'col-5 col-lg-3'}>
        <Link to={`/${context}`} className="c-postReturnLink">
          <ArrowLeftSVG className="c-icon mr-2" />
          <span
            className="c-postReturnLink__text"
            style={{
              textTransform: 'capitalize',
            }}
          >{`All ${context}`}</span>
        </Link>
      </div>
      {hide ? null : <Category />}
    </div>
  )
}

HeaderInfo.defaultProps = {}

HeaderInfo.propTypes = {
  date: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  categoryLink: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
}

export default HeaderInfo
