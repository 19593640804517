import React from 'react'
import PropTypes from 'prop-types'

const ShareButtons = ({ shareUrl }) => (
  <ul className="c-socialLinks c-socialLinks--verticle" data-datocms-noindex>
    <li className="c-socialLinks__item">
      <a
        className="c-socialLinks__link"
        href={`http://www.facebook.com/sharer.php?u=${shareUrl}`}
        target="_blank"
      >
        <img
          className="c-socialLinks__icon"
          srcSet="/images/icons/Social/Facebook.svg"
          src="/images/icons/Social/Facebook.png"
          alt="Facebook"
        />
      </a>
    </li>

    <li className="c-socialLinks__item">
      <a
        className="c-socialLinks__link"
        href={`https://twitter.com/share?url=${shareUrl}`}
        target="_blank"
      >
        <img
          className="c-socialLinks__icon"
          srcSet="/images/icons/Social/Twitter.svg"
          src="/images/icons/Social/Twitter.png"
          alt="Twitter"
        />
      </a>
    </li>

    <li className="c-socialLinks__item">
      <a
        className="c-socialLinks__link"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&summary=&source=Erudus`}
        target="_blank"
      >
        <img
          className="c-socialLinks__icon"
          srcSet="/images/icons/Social/LinkedIn.svg"
          src="/images/icons/Social/LinkedIn.png"
          alt="LinkedIn"
        />
      </a>
    </li>
  </ul>
)

ShareButtons.propTypes = {
  shareUrl: PropTypes.string.isRequired,
}

export default ShareButtons
